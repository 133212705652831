.page-content{
    color: black;
    margin-left: 0.8rem;
    margin-top:0.1rem;
   
    height: auto;
}
ul{
    margin: 0;
}

.thumb{
    display: block;
    margin-left:auto;
    margin-right:auto;
}