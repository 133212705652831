.profile{
    display: flex;
    
    
    height: auto;
    width: auto;
    vertical-align: stretch;
    padding: 0.3rem;
    margin: 0;

   
    top: 0;
}


.center-the-damn-thing{
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#headshot{
    padding: 0;
    width: auto;
    height: 8.5rem;
    border-radius: 0.5rem 1rem;
  
    
}

.info{
  
    margin-left: 0.5rem;
 
    
    
    
    
}

.social-links{
    height: 1.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.social-icon{
    width: 4rem;
    background-color: transparent;
    position: auto;
    
}


.social-links img{
    transition: height 0.75s, width 0.75s ;
    position: static;
    margin-right: 1.4rem;
    width: 1.4rem;
    height: 1.4rem;
}

.social-links img:hover{
    width: 1.6rem;
    height: 1.6rem;
    /*filters computed with https://codepen.io/sosuke/pen/Pjoqqp*/
    filter: invert(100%) invert(83%) sepia(91%) saturate(933%) hue-rotate(310deg) brightness(106%) contrast(88%);        
    -webkit-filter: invert(100%) invert(83%) sepia(91%) saturate(933%) hue-rotate(310deg) brightness(106%) contrast(88%);
}

h1 {
    
    padding: 0;
    text-align: left;
    position: static;
    
  
    color: #fff;
  }