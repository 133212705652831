
  .mobileBar{
    width: 100vw;
    
  }
  .mobileOptions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:0;
    position: relative;
    
   
    list-style-type: none;
    
  }
  
  .mobileOptions a{
    cursor: pointer;
    
    font-family: 'Titillium Web', sans-serif;
    line-height: 1;
    text-align: left;
    
    -webkit-transition: all 1.2s;
    -moz-transition: all 1.2s;
     -o-transition: all 1.2s;
        transition: all 1.2s; 
   
    display: flex;
    text-decoration: none;
    
  }
  .mobile_item_unactive a {
    color: black;
    font-size: 1.2rem;
    margin:  1.2rem .9rem .4rem .8rem;
    
  }
  
  .mobile_item_active a{

    letter-spacing: 0.1rem;
    
    font-weight: 200;
    
    border-bottom:  solid #f0c36c;
    font-size: 1.6rem;
    color: #f0c36c;
    margin:  .8rem .8rem .8rem .9rem;
  }

  

 