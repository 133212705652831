.all-contents{
  
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  color: white;
  
 
}




.left-sidebar{
  width:8.8rem;
  height: 100%;
  position: fixed;
  top: 0;
  float:left;
  overflow: auto;
  flex-shrink: 0;
  position: fixed;
  top: 0;
}

.right-column{
  display: flex;
  flex-direction: column;
  width: calc(100%-9.5rem);
  margin-left: 8.8rem;
  height: 100%;
  overflow-y: hidden;
}

.top{
  background: url(cypress-darkened.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 83%;
  height: 10.8rem;
  position: fixed;
  top:0;
  z-index: 10;
  
}

.page{
  
  
  
  height: 100%;
  padding-top: 10.3rem;
  z-index: 1;
  
  overflow-y: auto;
  background: rgba(255, 255, 255, 0.95);
  background-size: cover;
  
  
  
  
}



.menuBar{
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Universal Styles */

html {
  
  font-size: calc(5px + 1.5vw);
  background: url('./cypress_background.jpg') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.mobileMenu{
  display: none;
}

@media only screen and (max-width: 560px) {
  .menuBar, .left-sidebar .mobile-hide{
    display: none;
  }
  .mobileMenu{
    position: static;
    z-index: 10;
    display: inline-block;
    height: auto;
    background-color: rgb(73, 121, 136, 0.8);
   
  }
  
  .right-column{
    width: 100vw;
    margin-left: 0;
    display: flex;
    flex-direction: column; 
  }

  .top{
    
    width: 100%;
    position: static;
  }

  .page{
    
    padding-top: 0.6rem;
    /*height: 100%;*/
    position: static;
    z-index: 1;
    padding-bottom: 240px;
    /*overflow-y: auto;*/
    background: rgba(255, 255, 255, 0.95);
    background-size: cover;
  }

  h2{
    font-family: 'Titillium Web', sans-serif;
    margin: .2rem;
    font-size: 1.6rem;
    margin-top: 0.8rem;
    display: none;
  }

  html {
    
    background: url('./cypress_background.jpg') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    
  }
}

input:focus {
  outline: none;
}

/* Reusable Component - Image Container */

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: #000;
}
.column{
  display: flex;
  flex-direction: column;

}

.thumb-container{
  height: 12.5rem;
  display:flex;
  align-items: center;
}

.thumb{
  width: auto;
  height: 10rem;
  margin-top: 2em;
  margin-bottom: 0;
  transition: height 0.75s, width 0.75s, filter 0.75s, border 0.75s;
 
}

.smallItalic{
  font-size:0.6rem;
  font-style: italic;
  padding-top: 0.4rem;
}
.thumb:hover{
  width:auto;
  height:10.5rem;
  filter: contrast(10%) ;        
  -webkit-filter:contrast(120%);
  border: .2rem solid #f0c36c80;
  
  
}
.image-container img {
  width: 100%;
}
.description{
  font-size: 1.8vw;
}

/* App Styles */

@import url('https://fonts.googleapis.com/css2?family=Lato&family=Titillium+Web:wght@600&display=swap');

h1, .large-print{
  font-family: 'Titillium Web', sans-serif;
  margin: .2rem;
  font-size: 1.4rem;
}
h1, h2{
  letter-spacing: 0.1rem;
}

h3{
  font-family: 'Titillium Web', sans-serif;
  letter-spacing:0.1vw;
  margin-bottom: 0px;
  
  
}

p, .contact, .not-bold
.title {
  font-family: 'Lato', sans-serif;
  
  
} 
br{
  margin: 0;
  padding: 0;
}
p{
  margin: 0.75rem 0 0.75rem 0;
}
.app-content {
  
  color: black;
 
}