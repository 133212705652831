.menuBar {
  
  width: auto;
  
  margin: 0;
  padding: 0;
  background-color: rgb(73, 121, 136, 0.8);
  /*background-image: url('../App/sunny.jpg');
  background-size: cover;
  background-repeat: no-repeat aha;
  */
  }
 

  
  .menuOptions {
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    margin: 0px;
    padding: .25rem;
    flex: auto;
    list-style-type: none;
    
  }
  
  .menuOptions a{
    cursor: pointer;
    
    font-family: 'Titillium Web', sans-serif;
    
    line-height: 1;
    text-align: left;
    
    -webkit-transition: all 1.2s;
    -moz-transition: all 1.2s;
     -o-transition: all 1.2s;
        transition: all 1.2s; 
    display: flex;
    text-decoration: none;
    
  }
  .menu_item a {
    color: black;
    font-size: 1.2rem;
    margin: 2.5vh 5px 6vh 2px;
    height: 1rem;
  }
  
  .menu_item_active a{
    margin: .4rem 5px 6vh 2px;
    letter-spacing: 0.1rem;
    padding: 0 0 5px 0;
    font-weight: 200;
    height: 1.5rem;
    border-bottom:  solid #f0c36c;
    font-size: 1.6rem;
    color: #f0c36c;
  }

  .menuOptions a:hover {
    color: #d4cfcf;
    font-size: 1.6rem;
  }
